const PubSub = window.PubSub;
const $ = window.jQuery;

class UPLController {
    constructor() {
        PubSub.subscribe('ready', this.init.bind(this));
    }
    init() {
        function formatOption(option) {
            const $option = $(option.element);
            const $depth = $option.data('depth');
            const $text = $option.text();

            return $(`<div data-depth="${$depth}" style="padding-left: ${parseInt($depth) * 15}px;">${$text}</div>`);
        }

        $('select.upl-dropdown').css('width', '100%').select2({
            width: 'resolve',
            minimumResultsForSearch: -1,
            templateResult: formatOption,
            // templateSelection: formatOption,
        });
    }
}

export default new UPLController();
